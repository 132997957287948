import React from 'react'
import ReactPlayer from 'react-player'


const Video = () => {

    return (
        <React.Fragment>
            <div className="es-float-sec">
                        <h2 className="es-h2 es-bl-clr">Ρίξε μια ματιά!</h2>
                        <p className="es-main-p mx-auto es-bl-clr" style={{ maxWidth: "700px" }}>Όλα τα παραπάνω χαρακτηριστικά σου ακούγονται κινέζικα; Τσέκαρε με τα μάτια σου τις δυνατότητες ενός custom eshop που δημιουργήσαμε σε σύγκριση με ένα τυπικό WooCommerce ηλεκτρονικό κατάστημα.</p>

                        <div className='player-wrapper' style={{ marginTop: "4rem" }}>
                            <ReactPlayer
                                className='react-player'
                                url='https://admin.w3vitals.com/wp-content/uploads/2022/06/W3Vitals-E-shop-Landing-Page-1-1.mp4'
                                width='100%'
                                height='100%'
                                controls={true}

                            />
                        </div>
                    </div>
        </React.Fragment>
    )
}

export default Video;